import { render, staticRenderFns } from "./WListTileSubTitle.vue?vue&type=template&id=2d81053e"
import script from "./WListTileSubTitle.vue?vue&type=script&lang=js"
export * from "./WListTileSubTitle.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports