<template>
	<v-list-tile-sub-title v-bind="$attrs" v-on="$listeners">
		<slot></slot>
	</v-list-tile-sub-title>
</template>

<script>
/**
 * @displayName w-list-tile-sub-title
 *
 * @since 0.3.2
 */
export default {
	name: 'WListTileSubTitle'
}
</script>
